import { useEffect, useRef, useState } from 'react'
import useFirebase from './useFirebase'

const KEY = '_FIREBASE_DATABASE'

const useDatabase = () => {
  const firebase = useFirebase()
  const databaseRef = useRef()
  const [database, setDatabase] = useState(databaseRef.current)

  useEffect(() => {
    if (window[KEY]) {
      databaseRef.current = window[KEY]
      setDatabase(window[KEY])
    } else if (firebase) {
      import('firebase/database').then(({ getDatabase, connectDatabaseEmulator, ...rest }) => {
        const database = getDatabase(firebase)
        // if (process.env.NODE_ENV === 'development') {
        //   connectDatabaseEmulator(database, 'localhost', 9000)
        // }
        window[KEY] = {
          database,
          ...rest,
        }
        databaseRef.current = window[KEY]
        setDatabase(window[KEY])
      }).catch(e => {
        console.error(e)
      })
    }
  }, [firebase])

  return database
}

export default useDatabase
