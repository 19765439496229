import { Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import useDatabase from 'hooks/useDatabase'

const SameCount = ({ city }) => {
  const database = useDatabase()
  const [count, setCount] = useState()
  useEffect(() => {
    if (database) {
      const { database: db, ref, onValue } = database
      const statsRef = ref(db, `cityMatchStats/results/${city}`)
      onValue(
        statsRef,
        (snapshot) => {
          const data = snapshot.val()
          if (data) {
            setCount(data)
          }
        },
        { onlyOnce: true }
      )
    }
  }, [database])
  return (
    <Text mb="2" color="careGray">
      有
      <Text color="textColor" as="span">
        {count ?? '???'}
      </Text>
      人也和這個城市最速配
    </Text>
  )
}

export default SameCount
